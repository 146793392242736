import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate'

import karusselltuer_00 from '../../images/karusselltuer_00_1600.webp'
import karusselltuer_01 from '../../images/karusselltuer_01_1600.webp'
import karusselltuer_02 from '../../images/karusselltuer_02_1600.webp'
import karusselltuer_03 from '../../images/karusselltuer_03_1600.webp'
import karusselltuer_thumb_00 from '../../images/karusselltuer_00_400.webp'
import karusselltuer_thumb_01 from '../../images/karusselltuer_01_400.webp'
import karusselltuer_thumb_02 from '../../images/karusselltuer_02_400.webp'
import karusselltuer_thumb_03 from '../../images/karusselltuer_03_400.webp'

const context = {
  title: 'Karussell&#173;drehtür',
  description: 'Repräsentativer Eingang mit Funktion, wirkt Zugluft, Temperaturschwankungen, Lärm und Schmutz entgegen',
  text: 'Karusselltüren, auch Drehtrommeltüren genannt, können je nach Anforderung manuell, halbautomatisch oder vollautomatisch betrieben werden. Sie bestehen aus einem Drehkreuz und drei oder vier Trommelwänden bzw. -kammern. Die Karusselltür hat einen maximalen Durchmesser von 6000 mm und eine Einbauhöhe von bis zu 3000 mm. \n\n' +

  'Karusselldrehtüren sind besonders repräsentativ. Sie werden daher gerne in öffentlichen Gebäuden, Hotels oder hochwertigen Bürokomplexen eingesetzt. Neben ihrer eindrucksvollen Optik, ist die Karusselltür auch ein Alleskönner. Sie wirkt Zugluft, Temperaturschwankungen, Lärm und Schmutz entgegen, ist für den Einsatz im Flucht- und Rettungsweg zugelassen und bietet durch verschiedene Widerstandsklassen (RC2/RC3) einen besonderen Einbruchsschutz. \n\n' +

  'Nicht von der Stange -  jede Karusselldrehtür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten technisch und optisch perfekt ineinandergreifen, kann eine langfristig zufriedenstellende Eingangssituation entstehen. Wir von Automatiktür24 beraten Sie gerne und stehen für sämtliche Fragen zur Verfügung –smart, effizient, online.',
  features: [
    //'autschr',
    //'barrierefreiheit',
    //'brandschutz',
    'breakOut',
    'design',
    //'din',
    'energieeinsparung',
    'modernisierung',
    //'notStrom',
    'personenfrequenz',
    //'platzsparend',
    'transparenz',
    'einbruchhemmung',
    //'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    //'autschr',
    'din18650',
    //'dinEn170',
    //'eltvtr',
  ],
  carouselImages: [
    {
      original: karusselltuer_00,
      thumbnail: karusselltuer_thumb_00,
      originalAlt: 'Karusselldrehtür'
    },
    {
      original: karusselltuer_01,
      thumbnail: karusselltuer_thumb_01,
      originalAlt: 'Karusselldrehtür'
    },
    {
      original: karusselltuer_02,
      thumbnail: karusselltuer_thumb_02,
      originalAlt: 'Karusselldrehtür'
    },
    {
      original: karusselltuer_03,
      thumbnail: karusselltuer_thumb_03,
      originalAlt: 'Karusselldrehtür'
    },
  ],
  configurator: false,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
